import React from 'react';
import Layout from "../layouts";

export default class Pricing extends React.PureComponent {
	render() {
		return <Layout>
			<section className="main site-content pricing_page">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h1>The ridiculous API Pricing</h1>
							<h2>Free plan includes 1000 requests/day</h2>
						</div>
					</div>


					<div className="row ">
						<div className="col-md-12">
							<a href="http://docs.ciokan.apiary.io/" rel="noopener noreferrer" target="_blank"
							   className="btn btn-first">
								Documentation
							</a>
						</div>
					</div>
					<div className="row">
						<div className="pricing-carts">
							<div className="col-md-3">
								<div className="pricing-cart pricing-cart-1">
									<div className="price price-1">
										<h3 className="price-name">Basic</h3>
									</div>
									<div className="discount">
										<span className="label">Requests</span>
										<p className="requests">2k/day</p>
										<p className="requests small">2/sec</p>
									</div>
									<div className="unit-price">
										<span className="label">Price per request</span>
										<p className="unit-price">$0.00017</p>
									</div>
									<div className="total">
										<span className="label">Total</span>
										<p className="total">$10/mo</p>
									</div>
									<a href="https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=496JQE7NUVWAJ"
									   className="btn btn-first turq">
										Subscribe
									</a>
								</div>
							</div>
							<div className="col-md-3">
								<div className="pricing-cart pricing-cart-1">
									<div className="price price-2">
										<h3 className="price-name">PRO</h3>
									</div>
									<div className="discount">
										<span className="label">Requests</span>
										<p className="requests">20k/day</p>
										<p className="requests small">5/sec</p>
									</div>
									<div className="unit-price">
										<span className="label">Price per request</span>
										<p className="unit-price">$0.0001</p>
									</div>
									<div className="total">
										<span className="label">Total</span>
										<p className="total" id="shared_proxy-total">$60/mo</p>
									</div>
									<a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=U84AEGAPUGUDG"
									   className="btn btn-first turq">
										Subscribe
									</a>
								</div>
							</div>
							<div className="col-md-3">
								<div className="pricing-cart pricing-cart-2">
									<div className="price price-3">
										<h3 className="price-name">Mega</h3>
									</div>
									<div className="discount">
										<span className="label">Requests</span>
										<p className="requests">80k/day</p>
										<p className="requests small">20/sec</p>
									</div>
									<div className="unit-price">
										<span className="label">Price per request</span>
										<p className="unit-price">$0.00008</p>
									</div>
									<div className="total">
										<span className="label">Total</span>
										<p className="total">$199/mo</p>
									</div>

									<a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=Y3JGFVLV9DN7Q"
									   className="btn btn-first">
										Subscribe
									</a>
								</div>
							</div>
							<div className="col-md-3">
								<div className="pricing-cart pricing-cart-2">
									<div className="price price-3">
										<h3 className="price-name">Ultra</h3>
									</div>
									<div className="discount">
										<span className="label">Requests</span>
										<p className="requests">500k/day</p>
										<p className="requests small">500/sec</p>
									</div>
									<div className="unit-price">
										<span className="label">Price per request</span>
										<p className="unit-price">$0.00003</p>
									</div>
									<div className="total">
										<span className="label">Total</span>
										<p className="total">$499/mo</p>
									</div>
									<a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=8FTV2RZ2XL3HA"
									   className="btn btn-first">
										Subscribe
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="asks">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h2>Frequently asked questions</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="ask-item">
								<h3 className="qustion">Are there any rate limitations applied?</h3>
								<div className="answer">
									<p>
										We have some rate limits in place and you can find them listed in the pricing
										table of each plan.
										Anonymous users of our API are limited to performing only 1 request per second.
									</p>
								</div>
							</div>
							<div className="ask-item">
								<h3 className="qustion">Do I need an API key to make it work?</h3>
								<div className="answer">
									<p>
										No. You only need the token when you are subscribed to one of our plans and we
										use that
										information to recognize you and lift up the limitations.
									</p>
								</div>
							</div>
							<div className="ask-item">
								<h3 className="qustion">What payment methods do you accept?</h3>
								<div className="answer">
									<p>
										Our current payment processor is Paypal but you may contact us for alternative
										options
										if you can't use it for whatever reason.
									</p>
								</div>
							</div>
							<div className="ask-item">
								<h3 className="qustion">Do you support secure connections (https)?</h3>
								<div className="answer">
									<p>
										Yes, HTTPS is enabled on all connections regardless of any plan. Users with or
										without
										a token are encouraged to use HTTPS.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="ask-item">
								<h3 className="qustion">Do you have a trial?</h3>
								<div className="answer">
									<p>
										No, we do not have a trial. You can start with a free usage tier and see for
										yourself
										if the service is suitable for your needs. The free plan allows for 1000
										req/day.
									</p>
								</div>
							</div>
							<div className="ask-item">
								<h3 className="qustion">What happens when I hit my daily limit?</h3>
								<div className="answer">
									<p>
										When you hit your daily limit our servers will start responding with a status
										code of 429 which translates to &quot; Too many requests &quot;
									</p>
								</div>
							</div>
							<div className="ask-item">
								<h3 className="qustion">How fast is your server to respond?</h3>
								<div className="answer">
									<p>
										Very fast. We do cross region load balancing to respond from the nearest servers
										and we also perform a lot of in-memory optimizations to respond fast and at
										scale.
									</p>
								</div>
							</div>
							<div className="ask-item">
								<h3 className="qustion">I need moaarr! Do you provide any custom plans?</h3>
								<div className="answer">
									<p>
										Why not?! We can make it happen. Just hit the contact us button in the menu
										and tell us what you need.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	}
}
